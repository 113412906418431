import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;

// require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';
import './lib/header-menu';
import './lib/sticky-header';
import './lib/directory';
import './lib/tooltip';
import './lib/hs-chat';
import './lib/forms';


$(document).foundation();

var socialScreen = document.querySelector('#welcome-screen');
var socialLink = document.querySelector('#social-link');
var emailScreen = document.querySelector('#email-screen');
var emailLink = document.querySelector('#email-link');

function toEmail() {
    if (emailLink && socialScreen && emailScreen) {
        socialScreen.style.display = "none";
        emailScreen.style.display = "block";
    }
}

function toSocial() {
    if ( socialLink && socialScreen && emailScreen ) {
        socialScreen.style.display = "block";
        emailScreen.style.display = "none";
    }
}

if (emailLink) {
    emailLink.addEventListener('click', function(e) {
        e.preventDefault();
        toEmail();
    });
}

if ( socialLink ) {
    socialLink.addEventListener('click', function(e) {
        e.preventDefault();
        toSocial();
    });
}

// Avatar account menu
document.addEventListener('DOMContentLoaded', function() {
    var avatar = document.getElementById('avatar');
    var accountMenu = document.getElementById('account-menu');

    // check if the avatar and accountMenu elements exist
    if (!avatar || !accountMenu) {
        return;
    }

    avatar.addEventListener('click', function() {
        var display = accountMenu.style.display;
        accountMenu.style.display = display === 'none' ? 'block' : 'none';
    });

    document.addEventListener('click', function(event) {
        var isClickInside = avatar.contains(event.target) || accountMenu.contains(event.target);

        if (!isClickInside) {
        accountMenu.style.display = 'none';
        }
    });
});
// end Avatar account menu

// Help account menu
document.addEventListener('DOMContentLoaded', function() {
    var help = document.getElementById('help');
    var helpMenu = document.getElementById('help-menu');

    // check if the avatar and accountMenu elements exist
    if (!help || !helpMenu) {
        return;
    }

    help.addEventListener('click', function() {
        var display = helpMenu.style.display;
        helpMenu.style.display = display === 'none' ? 'block' : 'none';
    });

    document.addEventListener('click', function(event) {
        var isClickInside = help.contains(event.target) || helpMenu.contains(event.target);

        if (!isClickInside) {
        helpMenu.style.display = 'none';
        }
    });
});
// end Help account menu
  
// Tabs
document.querySelectorAll('.events__tabs-item').forEach(tab => {
    tab.addEventListener('click', function(event) {
        event.preventDefault(); // Prevent any default action, such as navigating to a link's href.

        // Remove active class from current active tab and content
        const activeTab = document.querySelector('.events__tabs-item--active');
        
        if (activeTab) {
            activeTab.classList.remove('events__tabs-item--active');
            
            const activeContent = document.querySelector(activeTab.dataset.tabTarget);
            if (activeContent) {
                activeContent.classList.remove('events__content--active');
            }
        }

        // Add active class to clicked tab and its content
        this.classList.add('events__tabs-item--active');
        
        const newContent = document.querySelector(this.dataset.tabTarget);
        if (newContent) {
            newContent.classList.add('events__content--active');
        }
        
        // Update the URL hash
        window.location.hash = this.dataset.tabTarget;
    });
});

// On page load, check for a hash and show the corresponding tab content
window.addEventListener('DOMContentLoaded', (event) => {
    const hash = window.location.hash;
    if(hash) {
        const tab = document.querySelector(`[data-tab-target="${hash}"]`);
        if(tab) {
            tab.click();
        }
    }
});
// end Tabs