// Toggle password visibility
document.addEventListener('DOMContentLoaded', function() {
 // Select all elements with class 'password__wrap'
 const passwordWraps = document.querySelectorAll('.password__wrap');

 // Iterate over each 'password__wrap' element
 passwordWraps.forEach(function(passwordWrap) {
     // Find the password field and toggle icon within the current 'password__wrap'
     const passwordField = passwordWrap.querySelector('input[type="password"]');
     const toggleIcon = passwordWrap.querySelector('.password__toggle');

     // Check if both the password field and the toggle icon exist
     if (passwordField && toggleIcon) {
         // Add a click event listener to the toggle icon
         toggleIcon.addEventListener('click', function() {
             togglePasswordVisibility(passwordField, toggleIcon);
         });
     }
 });
});

function togglePasswordVisibility(passwordField, toggleIcon) {
 // Check the current type of the password field
 if (passwordField.type === 'password') {
     // Change the type to 'text' to reveal the password
     passwordField.type = 'text';
     // Update the icon class to indicate the password is visible
     toggleIcon.classList.remove('password__toggle--eye');
     toggleIcon.classList.add('password__toggle--eye-off');
 } else {
     // Change the type back to 'password' to hide the password
     passwordField.type = 'password';
     // Update the icon class to indicate the password is hidden
     toggleIcon.classList.remove('password__toggle--eye-off');
     toggleIcon.classList.add('password__toggle--eye');
 }
}

// Toggle element visibility
document.addEventListener('click', function (e) {
// Check if the clicked element has the data-toggle-action attribute
if (e.target.matches('[data-toggle-action="toggle"]')) {
    // Get the target element by ID from the data-toggle-target attribute
    const targetId = e.target.getAttribute('data-toggle-target');
    const targetElement = document.getElementById(targetId);

    // If target exists, toggle visibility based on current display property
    if (targetElement) {
        const isHidden = targetElement.style.display === 'none';
        targetElement.style.display = isHidden ? 'block' : 'none';
    }
}
});
