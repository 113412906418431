// Upcoming events
if (typeof Swiper !== 'undefined') {
    var upcomingSwiper = new Swiper("#upcoming-events", {
        slidesPerView: 1.2,
        spaceBetween: 10,
        speed: 600,
        breakpoints: {
            640: {
                slidesPerView: 2.6,
                slidesPerGroup: 2,
                speed: 600,
            },
            768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                speed: 900,
            },
            1170: {
                slidesPerView: 4,
                slidesPerGroup: 4,
                speed: 800,
            },
        },
        navigation: {
            nextEl: ".upcoming-button-next",
            prevEl: ".upcoming-button-prev",
        },
    });
}
// end Upcoming events

// Recommended events
if (typeof Swiper !== 'undefined') {
    var recommendedSwiper = new Swiper("#recommended-events", {
        slidesPerView: 1.2,
        spaceBetween: 10,
        breakpoints: {
            640: {
                slidesPerView: 2.6,
                slidesPerGroup: 1,
            },
            768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
            },
            1170: {
                slidesPerView: 4,
                slidesPerGroup: 4,
            },
        },
        navigation: {
            nextEl: ".recommended-button-next",
            prevEl: ".recommended-button-prev",
        },
    });
}
// end Recommended events

// Remove event
$(document).ready(function () {
    $(".remove-event").click(function () {
      var role = $(this).attr("data-role");
      var confid = $(this).attr("data-confid");
      var name = $(this).attr("data-name");
      $("#sched-overlay-generic").show();
      $("#sched-overlay-detachrole").show();
      $("#sched-overlay-detachrole").children("#detachrole-directory").html(name + " " + role);
      $("#sched-overlay-generic-inner").children("#sched-overlay-button-red").unbind('click').click(function () {
        $.ajax({
          method: "GET",
          url: "/detach-account",
          data: {
            "role": role,
            "confid": confid
          },
          success: function success(response) {
            if (response == "OK") {
              location.reload();
            } else {
              alert("Error removing from directory! Please contact support.");
            }
          },
          error: function error(xhr, status) {
            alert("Error removing from directory! Please contact support.");
          }
        });
        $(this).unbind('click');
        $("#sched-overlay-generic").fadeOut(200, function () {
          $("#sched-overlay-detachrole").hide();
        });
      });
      return false;
    });
  });
  // end Remove event
  
  function initializeFilterModal() {
    var openModals = document.querySelectorAll(".filter-modal-open");
    var closeModals = document.querySelectorAll("#filter-modal-close"); // Changed to closeModals for consistency with querySelectorAll
    var searchInput = document.querySelector(".search-hero__input");

    openModals.forEach(function (openModal) {
        openModal.addEventListener("click", function () {
            document.body.classList.add("filter-modal-active");

            // Use setTimeout to delay focus
            setTimeout(function () {
                if (searchInput) {
                    searchInput.focus();
                }
            }, 100); // 100ms delay
        });
    });

    closeModals.forEach(function (closeModal) { // Iterate over all close buttons
        closeModal.addEventListener("click", function () {
            document.body.classList.remove("filter-modal-active");
        });
    });

    document.addEventListener("keydown", function (event) {
        if (event.key === "Escape" && document.body.classList.contains("filter-modal-active")) {
            document.body.classList.remove("filter-modal-active");
        }
    });
}

  initializeFilterModal();
  // end Open events filter in directory
  
  // Mobile event actions modal
  document.addEventListener('DOMContentLoaded', function () {
    // Get all instances of .event__actionsmobile-button
    var buttons = document.querySelectorAll('.event__actionsmobile-button');
    if (buttons.length === 0) {
      return;
    }
  
    // Loop through all buttons
    buttons.forEach(function (button) {
      // Find the closest .event__actionsmobile container to get its modal
      var container = button.closest('.event__actionsmobile');
      var modal = container.querySelector('.event__actionsmobile-modal');
      button.addEventListener('click', function () {
        var display = modal.style.display;
        modal.style.display = display === 'none' ? 'block' : 'none';
      });
    });
  
    // Close modal if clicked outside
    document.addEventListener('click', function (event) {
      buttons.forEach(function (button) {
        var container = button.closest('.event__actionsmobile');
        var modal = container.querySelector('.event__actionsmobile-modal');
        var isClickInside = button.contains(event.target) || modal.contains(event.target);
        if (!isClickInside) {
          modal.style.display = 'none';
        }
      });
    });
    
  });
  
  // end Mobile event actions modal